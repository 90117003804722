import React, {useEffect, useState} from 'react';
import axios from "axios";

const Listar = (props) => {
    //console.log(props.onibus);

    const [onibus, setOnibus] = useState([]);
    const [error, setError] = useState({error: 0, message: null});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setOnibus(props.onibus);
    }, [props.onibus]);

    const handleSearch = (e) => {
        let search = e.target.value;
        let newOnibus = props.onibus.filter(x => x.nome.toLowerCase().match(search.toLowerCase()));
        setOnibus(newOnibus);
    }

    const getItinerario = async (linha) => {
        setLoading(true);
        try{
            const result = await axios.get('http://www.poatransporte.com.br/php/facades/process.php?a=il&p=' + linha);

            let lat = result.data['0'].lat;
            let lng = result.data['0'].lng;

            //window.location.href = "https://www.google.com/maps/?q="+lat+","+lng;
            window.open("https://www.google.com/maps/?q="+lat+","+lng, "_blank");

            let newError = {
                ...error,
                error: 0,
                message: null
            }
            setError(newError);
            setLoading(false);
        }catch (e){
            //setError({error: 1, message: 'Não foi possível carregar os dados'});
            alert("Não foi possível carregar o itinerário");
            setLoading(false);
        }
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <input type="email" className="form-control" id="search" aria-describedby="search" placeholder="Informe a linha desejada" onChange={handleSearch}/>
                    <i className="fas fa-search ft icon-search"/>
                    <br/>
                </div>
                <div className="col-md-6 text-right">
                    <p className="ft">total de linhas {props.onibus.length}</p>
                </div>
            </div>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Código</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Mapa</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.error.error === 0 ? (
                        onibus.map((item, key) => {
                            return (
                                <tr key={"onibus"+key}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.codigo}</td>
                                    <td>{item.nome}</td>
                                    <td>
                                        {/*<a href={"https://www.google.com/maps/?q=-30.09975057831400000,-51.18992010780100000"} target="_blank">*/}
                                        <a onClick={() => getItinerario(item.id)} target="_blank" style={{cursor: 'pointer'}}>
                                            <i className="fas fa-map-marker-alt icon fl"/>
                                            <i className="fas fa-chevron-right icon ft mt-1"/>
                                        </a>
                                        {/*<a href={"https://www.google.com/maps/dir/'-30.09975057831400000,-51.18992010780100000'/'-30.03155357831400000,-51.22779610780100000'"} target="_blank">
                                            <i className="fas fa-map-marker-alt icon fl"/>
                                            <i className="fas fa-chevron-right icon ft mt-1"/>
                                        </a>*/}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <h2>{props.error.message}</h2>
                    )

                }
                </tbody>
            </table>
        </div>
    );
};

export default Listar;

