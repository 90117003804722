import React, {useEffect, useState} from 'react';
import logo from './grupo-mobilidade.png';
import './App.css';
import Onibus from "./components/onibus";


function App() {

    let [type, setType] = useState('o');

    useEffect(() => {
        if(setType){
            setType();
        }
    }, []);


    return (
    <div className="App">
      <header className="App-header">
          <div className="container">
              <div className="row text-center">
                  <div className="col-md-12">
                      <img src={logo} className="app-logo" alt="logo" />
                      <h1>DataPoa - Mobilidade</h1>
                      <br/><br/>
                  </div>
                  <div className="col-md-6">
                      <div className={"box " + (type === 'o' ? 'box-active' : '')} onClick={() => setType('o')}>
                          <h2>Linhas de ônibus</h2>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className={"box " + (type === 'l' ? 'box-active' : '')} onClick={() => setType('l')}>
                          <h2>Linhas de lotação</h2>
                      </div>
                  </div>
                  {/*<div className="col-md-4">
                      <div className="box">
                          <h2>Itinerário</h2>
                      </div>
                  </div>*/}
              </div>
          </div>

          <br/>
          <br/>
          <Onibus type={type}/>

      </header>
    </div>
    );
}

export default App;
