import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";

import Listar from './listar';
import axios from 'axios';

const Onibus = (props) => {

    const [onibus, setOnibus] = useState([]);
    const [itinerario, setItinerario] = useState([]);
    const [error, setError] = useState({error: 0, message: null});
    const [loading, setLoading] = useState(false);

    useEffect((props) => {
        listOnibus();
    }, [props.type]);

    const listOnibus = async () => {
        setLoading(true);
        try {
            let valueType = 'o';
            if(props.type){
                valueType = props.type;
            }
            //const result = await axios.get('http://www.poatransporte.com.br/php/facades/process.php?a=nc&p=%25&t=' + valueType);
            try{
                const result = await axios.get('http://www.poatransporte.com.br/php/facades/process.php?a=nc&p=%25&t=' + valueType);
                setOnibus(result.data)
                let newError = {
                    ...error,
                    error: 0,
                    message: null
                }
                setError(newError);
                setLoading(false);
            }catch (e){
                setError({error: 1, message: 'Não foi possível carregar os dados'});
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            {
                loading ? (
                    <div className="text-center">
                        <i className="fa fa-spinner fa-spin fa-2x"/>
                    </div>
                ) : (
                    <Listar onibus={onibus} error={error}/>
                )
            }

        </div>
    );
};

export default Onibus;
